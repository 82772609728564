import { Flex, VStack } from '@chakra-ui/react';
import { useInView } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';

import { useIsMobile } from '@endaoment-frontend/hooks';
import { GetPortfolioCategories } from '@endaoment-frontend/portfolios';
import { routes } from '@endaoment-frontend/routes';
import { ArrowIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button, CardDotsBackground } from '@endaoment-frontend/ui/shared';
import { AMPCategoryCard, PortfolioCategoryCard } from '@endaoment-frontend/ui/smart';

import leftDecoration from '../../images/decoration/portfolios-feature-decoration-left.png';
import rightDecoration from '../../images/decoration/portfolios-feature-decoration-right.png';
import portfolioOptionsShot from '../../images/screenshots/home/portfolio-options.png';

import styles from './PortfoliosFeatureSection.module.scss';

export const PortfoliosFeatureSection = () => {
  const { isMobile } = useIsMobile();
  const { data: portfolioCategories = [] } = GetPortfolioCategories.useQuery([], { staleTime: Infinity });
  const slicedCategories = isMobile ? portfolioCategories.slice(0, 2) : portfolioCategories.slice(-5);

  const containerRef = useRef(null);
  const inView = useInView(containerRef, { margin: '0px 0px -35% 0px' });

  return (
    <section id='portfolios-section' ref={containerRef} data-in-view={inView} className={styles['section']}>
      <div className={styles['box']}>
        <div className={styles['header']}>
          <Image src={leftDecoration} alt='' />
          <VStack>
            <LogoIcon color='currentColor' />
            <h2 id='portfolios-feature-title'>Grow donated assets on your own terms</h2>
            <h4 id='portfolios-feature-tagline'>
              Traditional or digital, impact investments or index funds, private or public—you craft the investment
              strategy.
            </h4>
          </VStack>
          <Image src={rightDecoration} alt='' />
        </div>
        <div className={styles['grid']}>
          {slicedCategories.map(category => {
            return (
              <Link href={routes.app.portfolios({ categoryIds: [category.id] })} key={category.name}>
                <PortfolioCategoryCard category={category} />
              </Link>
            );
          })}
          <AMPCategoryCard />
        </div>
        <div className={styles['spread']}>
          <div>
            <Image src={portfolioOptionsShot} alt='Endaoment portfolio options' />
          </div>
          <div>
            <h3>Set and Forget</h3>
            <p>
              Choose from targeted positions in specific investments or set allocation targets across multiple
              portfolios.
            </p>
          </div>
        </div>
        <Flex justifyContent='center' mt='1.5rem'>
          <Button as={Link} href={routes.app.portfolios()} variation='portfolio' filled>
            Explore Portfolios Options
            <ArrowIcon />
          </Button>
        </Flex>
        <CardDotsBackground className={styles['dots']} excludeCorner />
      </div>
    </section>
  );
};
